<template>
  <b10-base>
    <b10-toolbar
      :title="title"
    >
      <v-btn
        slot="right"
        icon
        @click.stop="clickSubmit($refs.form)"
      >
        <v-icon>{{ $vuetify.icons.values.check }}</v-icon>
      </v-btn>
    </b10-toolbar>
    <b10-page-content
      :padding="3"
    >
      <ValidationObserver
        ref="form"
      >
        <registrar-entrada-salida-form
          :id="null"
          :idvigilante="usuarioIdvigilante"
          :geolocalizacion="geolocalizacion"
          @change-values="changeFormData"
          @click-actualizar-geolocalizacion="capturarGeolocalizacion"
        />
      </ValidationObserver>
    </b10-page-content>
  </b10-base>
</template>

<script>
import { formPageMixin } from '@/mixins/formPageMixin'
import Data from './RegistrarEntradaSalidaData'
import { get, sync } from 'vuex-pathify'
import { captureGeolocationCheck, continuarConGeolocalizacion } from '@/utils/maps'
import { TABLA } from '@/utils/consts'
import RegistrarEntradaSalidaForm from './components/RegistrarEntradaSalidaForm'

export default {
  components: {
    RegistrarEntradaSalidaForm
  },
  mixins: [formPageMixin],
  data () {
    return {
      tipo: {
        entrada: 'entrada',
        salida: 'salida'
      },
      geolocalizacion: {},
    }
  },
  computed: {
    usuarioIdempleado: get('usuario/idempleado'),
    usuarioIdvigilante: get('usuario/idvigilante'),
    usuarioIdtecnico: get('usuario/idtecnico'),
    usuarioIdcomercial: get('usuario/idcomercial'),
    usuarioCapturarGeolocalizacion: get('usuario/capturarGeolocalizacion'),
    ...sync(
      'presencia',
      {
        presenciaFentrada: 'fentrada',
        presenciaDescripcion: 'descripcion',
        presenciaIdparteNovedad: 'idparteNovedad',
        presenciaIdpuestoServicio: 'idpuestoServicio',
        presenciaIdpuestoServicioServicio: 'idpuestoServicioServicio',
        presenciaIdempresa: 'idempresa',
      }
    )
  },
  async created () {
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.title = `Registrar ${this.routeParams.tipo}`
      // alertas
      let faltanRegistrarSalidas = false
      let faltaRegistrarEntrada = false
      const entradasSinSalida = await Data.selectEntradasSinSalida(this, this.usuarioIdempleado)
      if (this.routeParams.tipo === this.tipo.entrada) {
        faltanRegistrarSalidas = (entradasSinSalida.length > 0)
      }
      if (this.routeParams.tipo === this.tipo.salida) {
        faltanRegistrarSalidas = (entradasSinSalida.length > 1)
        faltaRegistrarEntrada = (entradasSinSalida.length === 0)
      }
      if (faltanRegistrarSalidas) {
        this.$alert.showSnackbarWarning(`Faltan registrar salidas. Al registrar esta ${this.routeParams.tipo} se registrarán todas las salidas faltantes.`)
      }
      if (faltaRegistrarEntrada) {
        this.$alert.showSnackbarWarning(`Falta indicar la entrada. Al registrar la salida se registrará la entrada faltante.`)
      }
      await this.capturarGeolocalizacion()
    },
    changeFormData (formData) {
      this.formData = formData
    },
    async recalcularGeolocalizaion () {
      // GLP-26708
      await this.capturarGeolocalizacion(true)
      if (this.geolocalizacion) {
        return {
          latitud: this.geolocalizacion.lat,
          longitud: this.geolocalizacion.lng
        }
      } else {
        return {
          latitud: this.formData.latitud,
          longitud: this.formData.longitud
        }
      }
    },
    async submitForm () {
      if (continuarConGeolocalizacion(this, this.usuarioCapturarGeolocalizacion, this.geolocalizacion)) {
        this.$loading.showManual(`Registrando la ${this.routeParams.tipo}`)
        let geolocalizacionUtilizada = await this.recalcularGeolocalizaion()
        try {
          let presencia = {}
          if (this.routeParams.tipo === this.tipo.entrada) {
            const idpuestoServicioServicio = this.formData.puesto_servicio_servicio?.idpuesto_servicio_servicio || null
            presencia = await Data.registrarEntrada(
              this,
              this.usuarioIdempleado,
              geolocalizacionUtilizada.latitud,
              geolocalizacionUtilizada.longitud,
              this.formData.observaciones,
              this.formData.idpunto,
              idpuestoServicioServicio
            )
            this.presenciaFentrada = presencia.fentrada.toISOString()
            // TODO: cambiar esto, en realidad no debería guardar en el store lo que devuelve el registro de presencia
            // Debería guardar lo que ha indicado el usuario, es decir, el puesto de servicio del servicio que ha indicado
            this.presenciaDescripcion = presencia.puesto_servicio_descripcion
            this.presenciaIdpuestoServicio = presencia.idpuesto_servicio
            this.presenciaIdparteNovedad = presencia.idparte_novedad
            this.presenciaIdpuestoServicioServicio = idpuestoServicioServicio
            this.presenciaIdempresa = presencia.idempresa
            // si el puesto de servicio tiene adjuntos se pregunta si se quieren ver
            const ficherosFicheroPuestoServicio = await Data.selectFicheroPuestoServicioCount(
              this,
              presencia.idpuesto_servicio,
              !!this.usuarioIdtecnico,
              !!this.usuarioIdcomercial,
              !!this.usuarioIdvigilante
            )
            if (ficherosFicheroPuestoServicio.count > 0 && this.hasViewPerm(this.permissions.puestoServicioAdjunto.id)) {
              this.$alert.showSnackbarWarning(
                'El puesto de servicio tiene ficheros adjuntos',
                {
                  actions: [{
                    text: 'Ver',
                    flat: true,
                    handler: () => {
                      this.verFicherosPuestoServicio(presencia.idpuesto_servicio)
                    }
                  }]
                }
              )
              this.$appRouter.go(-1)
            } else {
              this.$appRouter.go(-1)
            }
          } else {
            presencia = await Data.registrarSalida(
              this,
              this.usuarioIdempleado,
              geolocalizacionUtilizada.latitud,
              geolocalizacionUtilizada.longitud,
              this.formData.observaciones,
              this.formData.idpunto
            )
            this.presenciaFentrada = null
            this.presenciaDescripcion = null
            this.presenciaIdpuestoServicio = null
            this.presenciaIdparteNovedad = null
            this.presenciaIdpuestoServicioServicio = null
            this.presenciaIdempresa = null
            if (presencia && presencia.idpresencia) {
              await this.$dirty.modified(this.$dirty.ENTITIES.remote.presencia, presencia.idpresencia)
            }
            this.$appRouter.go(-1)
          }
          this.$alert.showSnackbarSuccess(`La ${this.routeParams.tipo} se ha registrado`)
        } finally {
          this.$loading.hide()
        }
      }
    },
    verFicherosPuestoServicio (idpuestoServicio) {
      this.$appRouter.push({
        name: 'ficheros__puestos-servicio-fichero-list',
        params: {
          id: idpuestoServicio,
          idtabla: TABLA.puesto_servicio.idtabla,
        },
      })
    },
    async capturarGeolocalizacion (silent = false) {
      this.geolocalizacion = await captureGeolocationCheck(this, this.usuarioCapturarGeolocalizacion, silent)
    }
  }
}
</script>
